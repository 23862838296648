import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Autocomplete, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { useApi } from 'Service/axios';
import { useNavigate, Link } from 'react-router-dom';
import CadastroAlmoxarifado from 'components/Almoxarifado/CadastroAlmoxarifado';
import CadastroLocalizacao from 'components/Localizacao/CadastroLocalizacao';

// components
import { MainBox, FormBox, TableBox, HeaderBox } from './style';
import { toast } from 'react-toastify';

function Localizacao() {
    const api = useApi();
    const navigate = useNavigate();
    const [formOpen, setFormOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [localizacaoId, setLocalizacaoId] = useState(null);
    const [localizacoes, setLocalizacoes] = useState([]);
    const [selectedLocalizacao, setSelectedLocalizacao] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getAllLocalizacoes = async () => {
        const response = await api.getAllLocalizacao();

        // const montarObjeto = await Promise.all(
        //     response.data.map(async (item) => {
        //         const almoxarifado = await api.getAlmoxarifadoById(item.id_almoxarifado);
        //         item.nome_almoxarifado = almoxarifado?.data[0]?.descricao;
        //         return item;
        //     })
        // );

        setLocalizacoes(response.data.localizacao);
    };

    // delete functions
    const handleClickOpenDelete = (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setLocalizacaoId(id);
    };
    const handleCloseDelete = (e) => {
        e.preventDefault();
        setOpenDelete(false);
    };
    const handleCancelDeleteDialog = () => {
        handleCloseDelete();
    };
    const handleConfirmDeleteDialog = async (localizacaoId) => {
        try {
            const deletedLocalizacao = await api.deleteLocalizacao(localizacaoId);
            toast.success('Deletado com sucesso!');
            getAllLocalizacoes();
            setOpenDelete(false);
        } catch (error) {
            if (error.response.status === 500) {
                toast.error('Erro. Esta Localização já está associada a um item!');
            }
            toast.error(error.message);
            setOpenDelete(false);
        }
    };

    useEffect(() => {
        getAllLocalizacoes();
    }, []);

    console.log(localizacoes);

    return (
        <MainCard title="Localização" sx={{ position: 'relative' }}>
            <Grid container spacing={gridSpacing}>
                <MainBox>
                    <Dialog
                        open={openDelete}
                        onClose={handleCloseDelete}
                        aria-labelledby="alert-dialog-title-delete"
                        aria-describedby="alert-dialog-description-delete"
                    >
                        <DialogTitle id="alert-dialog-title-delete">{'Deletar Registro'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-delete">
                                Tem certeza que deseja excluir o registo ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleConfirmDeleteDialog(localizacaoId)}>Sim</Button>
                            <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                        </DialogActions>
                    </Dialog>
                    {formOpen && (
                        <FormBox>
                            <Button variant="contained" onClick={() => setFormOpen(!formOpen)}>
                                Ver Tabela
                            </Button>
                            <Box sx={{ width: '80%', margin: '2em' }}>
                                <CadastroLocalizacao getAllLocalizacoes={getAllLocalizacoes} />
                            </Box>
                        </FormBox>
                    )}

                    {!formOpen && (
                        <TableBox sx={{ width: '100%' }}>
                            <HeaderBox>
                                <Button variant="contained" onClick={() => setFormOpen(!formOpen)}>
                                    Cadastrar Localização
                                </Button>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={localizacoes}
                                    getOptionLabel={(almoxarifado) => almoxarifado.descricao}
                                    value={selectedLocalizacao}
                                    onChange={(event, newValue) => {
                                        setSelectedLocalizacao(newValue);
                                    }}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Pesquisar localização" />}
                                />
                            </HeaderBox>

                            <TableContainer
                                component={Paper}
                                style={{
                                    width: '100%',
                                    height: 'calc(100vh - 340px)',
                                    overflowY: 'auto',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor: 'rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.3)'
                                }}
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell align="right">DESCRIÇÃO LOCALIZAÇÃO</TableCell>
                                            <TableCell align="right">NOME ALMOXARIFADO</TableCell>
                                            <TableCell align="right">AÇÕES</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {localizacoes.length > 0 &&
                                            localizacoes

                                                .filter((almoxarifado) =>
                                                    selectedLocalizacao
                                                        ? almoxarifado.descricao.includes(selectedLocalizacao.descricao)
                                                        : true
                                                )
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((item) => (
                                                    <TableRow key={item.id}>
                                                        <TableCell>{item.id}</TableCell>
                                                        <TableCell align="right">{item.descricao}</TableCell>
                                                        <TableCell align="right">{item.almoxarifado.descricao}</TableCell>
                                                        <TableCell align="right">
                                                            <Tooltip title="Editar">
                                                                <Link to={`/main/edit/localizacao/${item.id}`}>
                                                                    <IconButton>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </Link>
                                                            </Tooltip>
                                                            <Tooltip title="Excluir">
                                                                <IconButton onClick={(e) => handleClickOpenDelete(e, item.id)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={localizacoes.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableBox>
                    )}
                </MainBox>
            </Grid>
        </MainCard>
    );
}

export default Localizacao;
