import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { style } from '@mui/system';

export const MainBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

export const FormBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1em',
    marginLeft: '.7em'
});

export const TableBox = styled(Box)({});

export const HeaderBox = styled(Box)({
    position: 'absolute',
    top: '20px',
    right: '20px'
});
