import MainCard from 'ui-component/cards/MainCard';
import {
    Button,
    Grid,
    Box,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useApi } from 'Service/axios';
import { FormBox, HeaderBox, MainBox, TableBox } from './style';
import CadastroObras from 'components/Obras/CadastroObras';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

function Obras() {
    // === INSTÂNCIAS ===
    const api = useApi();
    // === STATES ===
    const [formOpen, setFormOpen] = useState(false);
    const [obras, setObras] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [obraId, setObraId] = useState(null);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'status', headerName: 'STATUS', flex: 1 },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Deletar Obra">
                        <IconButton onClick={(e) => handleOpenDelete(e, params.row.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar Obra">
                        <Link to={`/main/edit/obra/${params.row.id}`}>
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Box>
            )
        }
    ];

    // === FUNÇÕES ===

    const handleOpenDelete = (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setObraId(id);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleCancelDeleteDialog = (e) => {
        setOpenDelete(false);
    };

    const handleDeleteItem = async (e, obraId) => {
        e.preventDefault();
        try {
            await api.deleteObra(obraId);
            getObras();
            setOpenDelete(false);
            toast.success('Deletado com sucesso!');
        } catch (error) {
            if (error.response.status === 400) {
                toast.error('Erro. Esta Obra já está vinculada a um projeto!');
            }
            setOpenDelete(false);
            toast.error(error.message);
        }
    };

    const getObras = async () => {
        const response = await api.getAllObra();
        setObras(response.data.obras);
    };

    useEffect(() => {
        getObras();
    }, []);

    return (
        <MainCard title="Obras" sx={{ position: 'relative' }}>
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title-delete"
                aria-describedby="alert-dialog-description-delete"
            >
                <DialogTitle id="alert-dialog-title-delete">Deletar Registro</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-delete">Tem certeza que deseja excluir o registo ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleDeleteItem(e, obraId)}>Sim</Button>
                    <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={gridSpacing}>
                <MainBox sx={{ display: 'flex', justifyContent: 'center', padding: '1em', marginLeft: '.7em' }}>
                    {formOpen && (
                        <FormBox>
                            <Button variant="contained" onClick={() => setFormOpen(!formOpen)}>
                                Ver Tabela
                            </Button>
                            <Box sx={{ width: '80%', margin: '2em' }}>
                                <CadastroObras getAllObra={getObras} setFormOpen={setFormOpen} />
                            </Box>
                        </FormBox>
                    )}

                    {!formOpen && (
                        <TableBox sx={{ width: '100%' }}>
                            <HeaderBox>
                                <Button variant="contained" onClick={() => setFormOpen(!formOpen)}>
                                    Cadastrar obra
                                </Button>
                            </HeaderBox>

                            <DataGrid
                                rows={obras}
                                columns={columns}
                                sx={{ height: 'calc(100vh - 260px)', width: '100%' }}
                                onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                            />
                        </TableBox>
                    )}
                </MainBox>
            </Grid>
        </MainCard>
    );
}

export default Obras;
