// material-ui

// import logo from '../assets/images/Logos/logoTriunfo2.png';
import logo from '../assets/images/Logos/logo-nova.jpeg';

const Logo = ({ width, height }) => {
    return (
        <>
            <img
                src={logo}
                alt="Triunfo"
                width={width ? width : 170}
                height={height ? height : 70}
                style={{ backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}
            />
        </>
    );
};

export default Logo;
