import { Box, Button, Modal } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useApi } from 'Service/axios';

//pdf viewer
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { printPlugin } from '@react-pdf-viewer/print';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { ShoppingReportPdf } from './ShoppingReportPdf';
import { pdf } from '@react-pdf/renderer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24
};

export function ShoppingListReport() {
    const api = useApi();
    // PDF MODAL VIEWER PLUGINS
    const getFilePluginInstance = getFilePlugin();
    const { DownloadButton } = getFilePluginInstance;
    const printPluginInstance = printPlugin();
    const { PrintButton } = printPluginInstance;
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover, CurrentScale } = zoomPluginInstance;
    const [pdfBlob, setPdfBlob] = useState(null);

    // blod

    const [pdfBlobUrl, setPdfBlobUrl] = useState(null);

    //modal
    const [open, setOpen] = useState(false);
    const [list, setList] = useState([]);
    const handleOpen = () => setOpen(true);

    const [itensList, setItensList] = useState([]);
    const [solic, setSolic] = useState([]);
    const [allSolicitacoes, setAllSolicitacoes] = useState([]);

    const handleClose = () => {
        setOpen(false);
        setList([]);
        setSolic([]);
    };

    async function getShoppingListBySolicitationId(item) {
        if (item.status === 'Encerrado') return;
        try {
            const { data } = await api.getSolicitacaoById(item.id);
            const agrupados = agruparItensPorItemId(data.data);

            // Filtrando os itens com quantidade disponível menor que a quantidade necessária
            const filtered = agrupados.filter((item) => item.quantidade_disponivel < item.quantidade);

            // Criar um mapeamento dos itens da getShoppingList para acessar mov_sumarizado rapidamente
            const shoppingListData = await api.shopingList();

            const movSumarizadoMap = {};
            shoppingListData.data.solicitacoes.forEach((solicitacao) => {
                solicitacao.item_id.forEach((item) => {
                    movSumarizadoMap[item.item_id] = item.mov_sumarizado;
                });
            });

            // Adicionar mov_sumarizado correspondente ao filtered e calcular a soma de quantidade_disponivel
            const filteredWithMovSumarizado = filtered.map((item) => {
                const movSumarizado = movSumarizadoMap[item.item_id] || [];

                // Somando a quantidade_disponivel de todos os itens dentro de mov_sumarizado
                const totalQuantidadeDisponivel = movSumarizado.reduce((acc, mov) => acc + (mov.quantidade_disponivel || 0), 0);

                return {
                    ...item,
                    total_quantidade_disponivel: totalQuantidadeDisponivel // Adicionando a soma ao objeto final
                };
            });

            setList((prev) => [...prev, ...filteredWithMovSumarizado]);
        } catch (error) {
            console.error('Erro ao obter lista de compras:', error.message);
        }
    }

    async function getShoppingList() {
        try {
            const { data } = await api.shopingList();
            const solicitacoes = data.solicitacoes;

            if (!Array.isArray(solicitacoes)) {
                throw new Error('A resposta da API não contém um array de solicitações.');
            }
            data?.solicitacoes.forEach((item) => {
                getShoppingListBySolicitationId(item);
            });

            const uniqueConstructions = solicitacoes.reduce((acc, item) => {
                const key = item.obra.descricao.trim().toLowerCase(); // Normaliza a chave

                if (!acc.some((obj) => obj.obra.descricao.trim().toLowerCase() === key)) {
                    acc.push(item); // Adiciona o objeto apenas se não existir no acumulador
                }

                return acc;
            }, []);

            setSolic(uniqueConstructions);

            setAllSolicitacoes(solicitacoes);
        } catch (error) {
            console.error('Erro ao obter lista de compras:', error.message);
        }
    }

    function agruparItensPorItemId(solicitacoes) {
        const agrupados = [];

        if (solicitacoes?.item_id && Array.isArray(solicitacoes.item_id)) {
            solicitacoes.item_id.forEach((item) => {
                const novoItem = {
                    item_id: item.item_id,
                    descricao: item.item?.descricao || 'Descrição não disponível',
                    unidade_medida: item.item?.unidade_medida?.descricao || 'Unidade não disponível',
                    quantidade: item.quantidade,
                    quantidade_disponivel: 0
                };
                agrupados.push(novoItem);
            });
        }
        return agrupados;
    }

    // useEffect(() => {
    //     const uniqueItems = Array.from(
    //         new Set(itensList.map((item) => item.solicitacao_id)) // Extrai IDs únicos
    //     ).map((id) => {
    //         // Retorna o primeiro item correspondente ao ID
    //         return itensList.find((item) => item.solicitacao_id === id);
    //     });

    //     // const allDataAgrupados = allSolicitacoes.filter((item) => uniqueItems.some((itens) => item.id === itens.solicitacao_id));

    //     // setSolic(allDataAgrupados);
    // }, [itensList, allSolicitacoes]);

    useEffect(() => {
        if (open) getShoppingList(); // Chama a função ao abrir o modal
    }, [open]);

    useEffect(() => {
        const generateBlob = async () => {
            const asPdf = pdf(<ShoppingReportPdf data={list} solicitacoes={solic} />);
            const blob = await asPdf.toBlob();
            const blobUrl = URL.createObjectURL(blob);
            setPdfBlobUrl(blobUrl);
        };

        generateBlob();
    }, [list, solic]);

    return (
        <div>
            <Button onClick={handleOpen} variant="contained">
                Relatório de compras
            </Button>

            <Modal open={open} onClose={() => handleClose(false)}>
                <Box sx={{ ...style, width: '90vw', height: '90vh' }}>
                    {pdfBlobUrl && (
                        <>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: '1rem',
                                    alignItems: 'center',
                                    backgroundColor: '#eeeeee',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.3)'
                                }}
                            >
                                <DownloadButton />
                                <PrintButton />
                                <ZoomOutButton />
                                <ZoomPopover />
                                <ZoomInButton />
                            </Box>
                            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
                                <Viewer
                                    defaultScale={1.5}
                                    fileUrl={pdfBlobUrl}
                                    plugins={[getFilePluginInstance, printPluginInstance, zoomPluginInstance]}
                                />
                            </Worker>
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    );
}
