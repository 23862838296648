import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, Divider, FormControl, IconButton, MenuItem, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { IconX } from '@tabler/icons';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { useApi } from 'Service/axios';
import { v4 } from 'uuid';
import CadastroItensModal from './CadastroItens';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
};

export function XmlModal({ open, setOpen, handleButtonClick, xmlResponse, setCampos, setItens, handleClose, setXmlIsChanged }) {
    const [rows, setRows] = useState([]);
    const [listAllItens, setListAllItens] = useState([]);
    const [listAllAlmoxarifado, setListAllAlmoxarifado] = useState([]);
    const [listAllLocalizacao, setListAllLocalizacao] = useState([]);
    const [listAllFornecedor, setListAllFornecedor] = useState([]);

    const api = useApi();

    const getAllData = async () => {
        try {
            const [responseAlmoxarifado, responseLocalizacao, responseItens, responseFornecedor] = await Promise.all([
                api.getAllAlmoxarifado(),
                api.getAllLocalizacao(),
                api.getAllItens(),
                api.getAllFornecedor()
            ]);
            setListAllAlmoxarifado(responseAlmoxarifado.data.almoxarifados);
            setListAllLocalizacao(responseLocalizacao.data.localizacao);
            setListAllItens(responseItens.data.itens);
            setListAllFornecedor(responseFornecedor.data.fornecedores);
        } catch (error) {
            toast.error(error?.message);
        }
    };

    function handleChangeItem(selectedItem, uuid) {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.uuid === uuid
                    ? {
                          ...row,
                          id_sistema: selectedItem?.id,
                          descricao_sistema: selectedItem?.descricao,
                          unidade_sistema: selectedItem?.unidade?.descricao,
                          almoxarifado_sistema: selectedItem?.almoxarifado?.id,
                          localizacao_sistema: selectedItem?.localizacao?.id
                      }
                    : row
            )
        );
    }

    function atualizarCampoItem(uuid, campo, valor) {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.uuid === uuid
                    ? {
                          ...row,
                          [campo]: valor
                      }
                    : row
            )
        );
    }

    const columns = [
        { field: 'id', headerName: 'CODIGO', flex: 0.5 },
        {
            field: 'descricao',
            headerName: 'DESCRIÇÃO',
            flex: 2,
            renderCell: (params) => <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{params?.row?.descricao}</div>
        },
        { field: 'unidade', headerName: 'UN', flex: 0.4 },
        { field: 'quantidade', headerName: 'QTD', flex: 0.5 },
        // { field: 'lote', headerName: 'LOTE', flex: 1 },
        {
            field: 'descricao_sistema',
            headerName: 'DESCRIÇÃO',
            flex: 2.5,
            renderCell: (params) => {
                return (
                    <FormControl fullWidth>
                        <Autocomplete
                            options={listAllItens}
                            getOptionLabel={(item) => `ID - ${item?.id} | ${item?.descricao} | ${item?.unidade_medida?.descricao}` || ''}
                            value={listAllItens.find((el) => el.id === params?.row?.id_sistema) || null}
                            onChange={(e, newValue) => {
                                if (newValue) {
                                    handleChangeItem(newValue, params.row.uuid);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Item" fullWidth InputLabelProps={{ shrink: true }} />}
                        />
                    </FormControl>
                );
            }
        },
        // { field: 'unidade_sistema', headerName: 'UN', flex: 1 },
        {
            field: 'quantidade_sistema',
            headerName: 'QTD',
            flex: 1,
            renderCell: (params) => (
                <FormControl fullWidth>
                    <TextField
                        label="QTD"
                        value={parseInt(params?.row?.quantidade_sistema || 0, 10)} // Converte para inteiro
                        onChange={(e) => {
                            const value = parseInt(e.target.value.replace(/\D/g, ''), 10) || 0; // Remove não numéricos
                            atualizarCampoItem(params.row.uuid, 'quantidade_sistema', value);
                        }}
                        InputProps={{
                            inputProps: { min: 0 } // Evita valores negativos, opcional
                        }}
                    />
                </FormControl>
            )
        },
        {
            field: 'almoxarifado_sistema',
            headerName: 'ALM',
            flex: 1,
            renderCell: (params) => (
                <FormControl fullWidth>
                    <TextField
                        label="Almox."
                        select
                        value={params?.row?.almoxarifado_sistema || ''}
                        onChange={(e) => atualizarCampoItem(params.row.uuid, 'almoxarifado_sistema', e.target.value)}
                    >
                        {listAllAlmoxarifado.map((almoxarifado) => (
                            <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                {almoxarifado.descricao}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )
        },
        {
            field: 'localizacao_sistema',
            headerName: 'LOC',
            flex: 1,
            renderCell: (params) => (
                <FormControl fullWidth>
                    <TextField
                        label="Local."
                        select
                        value={params?.row?.localizacao_sistema || ''}
                        onChange={(e) => atualizarCampoItem(params.row.uuid, 'localizacao_sistema', e.target.value)}
                    >
                        {listAllLocalizacao.map((localizacao) => (
                            <MenuItem key={localizacao.id} value={localizacao.id}>
                                {localizacao.descricao}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )
        }
    ];

    function handleConfirmItems(data, rows) {
        const date = new Date();
        const currentDay = String(date.getDate()).padStart(2, '0');
        const currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        const currentYear = date.getFullYear();
        const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        const body = {
            documento: data?.numero_documento,
            emissao: data?.data_emissao,
            movimento: currentDate,
            fornecedor: data?.fornecedor?.id,
            fornecedorData: data?.fornecedor,
            observacao: '',
            itens: rows.map((element) => ({
                uuid: element?.uuid,
                item_id: element?.id_sistema,
                descricao: element?.descricao_sistema,
                quantidade: element?.quantidade_sistema,
                unidade: element?.unidade_sistema,
                valor_unitario: parseFloat(element?.valor_unitario),
                valor_total: parseFloat(element?.valor_total),
                almoxarifado_id: element?.almoxarifado_sistema,
                localizacao_id: element?.localizacao_sistema,
                lote: element?.lote_sistema,
                psicotropico: element?.psicotropico_sistema,
                validade: element?.validade_sistema,
                observacao: ''
            }))
        };

        setCampos(body);
        setItens((prevItens) => [...prevItens, ...body?.itens]);
        setXmlIsChanged((prev) => prev + 1);
        handleClose();
    }

    useEffect(() => {
        if (open) {
            getAllData();
            const dadosFormatados = xmlResponse?.itens?.map((el) => ({
                ...el,
                uuid: v4(),
                id: el?.codigo,
                id_sistema: '',
                descricao_sistema: '',
                unidade_sistema: '',
                quantidade_sistema: el?.quantidade,
                almoxarifado_sistema: '',
                localizacao_sistema: '',
                lote_sistema: ''
            }));
            setRows(dadosFormatados);
        }
    }, [open, xmlResponse]);

    return (
        <>
            <Button
                variant="contained"
                onClick={() => {
                    // setOpen(true);
                    handleButtonClick();
                }}
            >
                Importar XML
            </Button>

            <Modal open={open} onClose={handleButtonClick} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3>Importador XML</h3>
                        <Box>
                            <CadastroItensModal getItens={getAllData} variant="contained" />
                            <Tooltip title="Fechar" placement="top">
                                <IconButton onClick={() => setOpen(false)}>
                                    <IconX size={30} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Divider sx={{ margin: '10px 0' }} />
                    <Box sx={{ width: '100%', display: 'flex', gap: '1rem' }}>
                        <FormControl fullWidth>
                            <TextField value={xmlResponse?.numero_documento} label="Nota" disabled InputLabelProps={{ shrink: true }} />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField value={xmlResponse?.data_emissao} label="Data Emissão" disabled InputLabelProps={{ shrink: true }} />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                value={`${xmlResponse?.fornecedor?.nome_fantasia} - ${xmlResponse?.fornecedor?.cnpj}`}
                                label="Fornecedor"
                                InputLabelProps={{ shrink: true }}
                                disabled
                            />
                        </FormControl>
                    </Box>

                    <Box sx={{ width: '100%', height: 450, margin: '1rem 0 1rem 0' }}>
                        {rows?.length > 0 && (
                            <DataGrid
                                columns={columns}
                                rows={rows}
                                hideFooter
                                getRowId={(row) => row.id}
                                rowHeight={75}
                                disableRowSelectionOnClick
                            />
                        )}
                    </Box>

                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '30%', display: 'flex', justifyContent: 'space-between' }}>
                            <TextField InputLabelProps={{ shrink: true }} value={xmlResponse?.imposto} label="Imposto" disabled />
                            <TextField InputLabelProps={{ shrink: true }} value={xmlResponse?.desconto} label="Desconto" disabled />
                        </Box>
                        <Box sx={{ width: '30%', display: 'flex', justifyContent: 'space-between' }}>
                            <TextField InputLabelProps={{ shrink: true }} value={xmlResponse?.valor_nota} label="Valor Nota" disabled />
                            <Button
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                disabled={rows?.some((item) => !item.localizacao_sistema || !item.descricao_sistema)}
                                variant="contained"
                                onClick={() => handleConfirmItems(xmlResponse, rows)}
                            >
                                Confirmar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
