import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Select,
    Typography,
    MenuItem,
    Modal,
    Divider
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import InputMask from 'react-input-mask';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Context } from 'context/UserContext';
import { useApi } from 'Service/axios';
import { FormBox } from './style';
import { useContext } from 'react';
import { removeMask } from 'utils/removeMask';
import { toast } from 'react-toastify';
import { IconX } from '@tabler/icons';

const CadastroItensModal = ({ getItens, ...others }) => {
    const theme = useTheme();
    const api = useApi();
    const scriptedRef = useScriptRef();
    const [categorias, setCategorias] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [almoxarfados, setAlmoxarifados] = useState([]);
    const [localizacoes, setLocalizacoes] = useState([]);
    const [almoxarifadoLocalizacoes, setAlmoxarifadoLocalizacoes] = useState([]);
    const get = async () => {
        const categoriaResponse = await api.getAllCategory();
        const unidadeResponse = await api.getAllUnit();
        const almoxarfadosResponse = await api.getAllAlmoxarifado();
        const localizacoesResponse = await api.getAllLocalizacao();

        setCategorias(categoriaResponse.data.categorias);
        setUnidades(unidadeResponse.data.unidades);
        setAlmoxarifados(almoxarfadosResponse.data.almoxarifados);
        setLocalizacoes(localizacoesResponse.data.localizacao);
    };

    const handleRegister = async (values) => {
        // monta um objeto apenas com valores !== de ""
        const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});

        // monta o payload final
        const Item = filteredValues;
        console.log(Item);
        try {
            await api.createItem(filteredValues);
            getItens();
            toast.success('Cadastrado com sucesso!');
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    const clearValues = (values) => {
        values.descricao = '';
        values.apelido = '';
        values.unidade_medida_id = '';
        values.categoria_id = '';
        values.quantidade_minima = '';
        values.lote_economico = '';
        values.almoxarifado_id = '';
        values.localizacao_id = '';
    };

    useEffect(() => {
        get();
    }, []);
    //teste

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        height: '90%',
        borderRadius: '5px',
        overflow: 'auto'
    };

    return (
        <Formik
            initialValues={{
                descricao: '',
                apelido: '',
                unidade_medida_id: '',
                categoria_id: '',
                quantidade_minima: '',
                lote_economico: '',
                almoxarifado_id: '',
                localizacao_id: '',
                ativo: false,
                submit: null
            }}
            validationSchema={Yup.object().shape({
                descricao: Yup.string().required('Descrição é obrigatório'),
                unidade_medida_id: Yup.string().required('Uniadade é obrigatório')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                    handleRegister(values);
                    clearValues(values);
                } catch (err) {
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.msg });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <>
                    <Button onClick={handleOpen} variant={others.variant}>
                        Adicionar item
                    </Button>
                    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Box>
                                <h2 style={{ textAlign: 'center' }}>Inserir novo item</h2>
                                <IconX onClick={handleClose} style={{ position: 'absolute', top: '20px', right: '20px' }} />
                            </Box>
                            <Divider />
                            <form noValidate onSubmit={handleSubmit} {...others} style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.descricao && errors.descricao)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-descricao-register">Descrição</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-descricao-register"
                                            type="text"
                                            value={values.descricao}
                                            name="descricao"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.descricao && errors.descricao && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.descricao}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                        <InputLabel htmlFor="outlined-adornment-apelido-register">Apelido</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-apelido-register"
                                            type="text"
                                            value={values.apelido}
                                            name="apelido"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                    </FormControl>
                                </FormBox>

                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.unidade_medida_id && errors.unidade_medida_id)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-unidade_medida_id-register">Unidade de Medida</InputLabel>
                                        <Select
                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                            id="outlined-adornment-unidade_medida_id-register"
                                            type="text"
                                            value={values.unidade_medida_id}
                                            name="unidade_medida_id"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        >
                                            {unidades.length > 0 &&
                                                unidades.map((item) => (
                                                    <MenuItem value={item.id} key={item.id}>
                                                        {item.descricao}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {touched.unidade_medida_id && errors.unidade_medida_id && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.unidade_medida_id}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                        <InputLabel htmlFor="outlined-adornment-categoria_id-register">Categoria</InputLabel>
                                        <Select
                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                            id="outlined-adornment-categoria_id-register"
                                            type="text"
                                            value={values.categoria_id}
                                            name="categoria_id"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        >
                                            {categorias.length > 0 &&
                                                categorias.map((item) => (
                                                    <MenuItem value={item.id} key={item.id}>
                                                        {item.descricao}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </FormBox>

                                <FormBox>
                                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                        <InputLabel htmlFor="outlined-adornment-quantidade_minima-register">Quantidade Mínima</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-quantidade_minima-register"
                                            type="number"
                                            value={values.quantidade_minima}
                                            name="quantidade_minima"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                        <InputLabel htmlFor="outlined-adornment-lote_economico-register">Lote Econômico</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-lote_economico-register"
                                            type="number"
                                            value={values.lote_economico}
                                            name="lote_economico"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                    </FormControl>
                                </FormBox>

                                <FormBox>
                                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                        <InputLabel htmlFor="outlined-adornment-almoxarifado_id-register">Almoxarifado</InputLabel>
                                        <Select
                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                            id="outlined-adornment-almoxarifado_id-register"
                                            type="text"
                                            value={values.almoxarifado_id}
                                            name="almoxarifado_id"
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                handleChange(event);
                                                const selectedAlmoxarifadoId = event.target.value;
                                                const localizacoesForAlmoxarifado = localizacoes.filter(
                                                    (item) => item.id_almoxarifado === selectedAlmoxarifadoId
                                                );
                                                setAlmoxarifadoLocalizacoes(localizacoesForAlmoxarifado);
                                            }}
                                            inputProps={{}}
                                        >
                                            {almoxarfados.length > 0 &&
                                                almoxarfados.map((item) => (
                                                    <MenuItem value={item.id} key={item.id}>
                                                        {item.descricao}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                        <InputLabel htmlFor="outlined-adornment-localizacao_id-register">Localizacao</InputLabel>
                                        <Select
                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                            id="outlined-adornment-localizacao_id-register"
                                            type="text"
                                            value={values.localizacao_id}
                                            name="localizacao_id"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                            disabled={!values.almoxarifado_id} // Desabilita o campo até que um almoxarifado seja selecionado
                                        >
                                            {almoxarifadoLocalizacoes.length > 0 &&
                                                almoxarifadoLocalizacoes.map((item) => (
                                                    <MenuItem value={item.id} key={item.id}>
                                                        {item.descricao}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </FormBox>

                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}

                                {/* BOTÕES */}
                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            sx={{ marginBottom: '3em' }}
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            {isSubmitting ? 'Dados do item estão faltando' : 'Cadastrar Item'}
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>
                        </Box>
                    </Modal>
                </>
            )}
        </Formik>
    );
};

export default CadastroItensModal;
